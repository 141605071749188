import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import HeroBanner from '../../components/Hero'

const hero_settings = {
  size: 'is-fullheight',
  particle: true,
  images: '',
  css: '#4568dc,#b06ab3~#b06ab3,#4568dc',
  font_color: '#fff',
}

const ExamplesPage = ({
  data: {
    allMarkdownRemark: { group },
    site: { siteMetadata: { title } },
  },
}) => (
  <div>
    <Helmet title={`Examples of our work | ${title}`} />

    <HeroBanner
      title={'Examples of our work'}
      subtitle={'virtual tours, websites to case studies'}
      hero_settings={hero_settings}
    />

    <section className='section is-feature-grey edge--top'>
      <div className='container content'>
        <ul className='taglist'>
          {group.map(example_tag => (
            <li key={example_tag.fieldValue}>
              <Link to={`/examples/${kebabCase(example_tag.fieldValue)}/`}>
                <div className='tags are-large has-addons'>
                  <span className='tag is-link is-light'>{example_tag.fieldValue}</span>
                  <span className='tag is-primary'>{example_tag.totalCount}</span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  </div>
)

export default ExamplesPage

export const examplePageQuery = graphql`
    query ExamplesQuery {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___example_tags) {
                fieldValue
                totalCount
            }
        }
    }
`
